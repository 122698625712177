import {
	prepareIncomingData
} from 'assets#/js/utils';

$.ajaxSetup({
	method: 'POST',
	beforeSend: function (xhr, url) {
		if (!url.crossDomain) {
			xhr.setRequestHeader('X-CSRF-TOKEN', $('meta[name="csrf-token"]').attr('content'));
		}
	},
	headers: {
		'Content-Language': document.documentElement.lang,
		'Accept': 'application/json, text/javascript, */*; q=0.01'
	}
});

$(document).ajaxComplete(function (event, jqxhr, settings) {
	let response = prepareIncomingData(jqxhr.responseJSON);

	if (!response) {
		return false;
	}

	if (response.notyMessage && typeof response.notyMessage === 'string') {
		window.Notifier.create({
			type: response.success ? 'success' : (!response.success || response.error || response.errors) ? 'error' : 'info',
			timeout: 3000,
			text: response.notyMessage
		}).show();
	}

	if (response.success && response.mfp) {
		$.magnificPopup.open({
			items: {
				src: response.mfp
			},
			type: 'inline',
			mainClass: 'mfp-animate-zoom-in',
			removalDelay: 300,
			modal: !!response.modal,
			callbacks: {
				open: function () {},
				close: function () {}
			}
		});
		return;
	}

	if (response.reload || window.location.href === response.redirect) {
		return window.location.reload();
	}

	if (response.redirect) {
		return (window.location.href = response.redirect);
	}

	if (response.success) {
		if (response.mfpClose) {
			$.magnificPopup.close();
		}
	}
});
