'use strict';

/**
 * @module
 */

// ----------------------------------------
// Imports
// ----------------------------------------

import Browserizr from 'browserizr';
import Modernizr from 'modernizr';

// ----------------------------------------
// Public
// ----------------------------------------

Modernizr.addTest('mobile', Browserizr.detect().isMobile());
