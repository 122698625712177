'use strict';

function concatObjects (...sources) {
	let result = {};
	for (const source of sources) {
		if (source instanceof Array) {
			if (!(result instanceof Array)) {
				result = [];
			}

			result = result.concat(source);
		} else if (source instanceof Object) {
			for (let key in source) {
				let value = source[key];
				if (value instanceof Object && key in result) {
					value = concatObjects(result[key], value);
				}

				result[key] = value;
			}
		}
	}
	return result;
}

export default concatObjects;
