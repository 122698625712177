import './sass/index.scss';
import cookieNotice from './cookie-notice';

export default function () {
	const cookies = cookieNotice({
		allowUsage: {
			lang: window.WEZOM_DATA.language
		}
	});
	cookies.askUsage();
}
