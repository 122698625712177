'use strict';

function simpleEncode (input) {
	let result = JSON.stringify(input);
	return window.btoa ? window.btoa(result) : window.encodeURIComponent(result);
}

function simpleDecode (input) {
	let result = window.atob ? window.atob(input) : window.decodeURIComponent(input);
	return JSON.parse(result);
}

export { simpleEncode, simpleDecode };
