'use strict';

const presets = {
	side: {
		position: 'bottom-right',
		container: {
			width: '600px'
		},
		link: {
			href: 'http://demo.wezom.agency/cookies/index.html'
		},
		image: {
			src: 'http://demo.wezom.agency/public/images/info.png'
		}
	},
	flat: {
		container: {
			boxShadow: 'none'
		},
		button: {
			boxShadow: 'none',
			borderRadius: '0'
		}
	},
	round: {
		container: {
			bg: 'rgba(79, 79, 79, 1)',
			boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.3)'
		},
		text: {
			color: 'rgba(245, 245, 246, 1)'
		},
		link: {
			href: 'http://demo.wezom.agency/cookies/index.html',
			color: 'rgba(245, 245, 246, 1)',
			colorHover: 'rgba(255, 255, 255, 1)'
		},
		button: {
			bg: 'rgba(245, 245, 246, 1)',
			bgHover: 'rgba(255, 255, 255, 1)',
			color: 'rgba(79, 79, 79, 1)',
			boxShadow: 'none',
			borderRadius: '25px'
		}
	},
	opacity: {
		position: 'bottom-center',
		container: {
			bg: 'rgba(177, 206, 218, 0.8)',
			width: '600px',
			boxShadow: 'none'
		},
		text: {
			color: 'rgba(58, 97, 112, 1)'
		},
		link: {
			href: 'http://demo.wezom.agency/cookies/index.html',
			color: 'rgba(21, 76, 97, 1)',
			colorHover: 'rgba(10, 51, 66, 1)'
		},
		button: {
			boxShadow: 'none',
			borderRadius: '25px',
			bg: 'rgba(21, 76, 97, 1)',
			bgHover: 'rgba(10, 51, 66, 1)',
			color: 'rgba(177, 206, 218, 1)'
		}
	}
};

function presetSample (params) {
	return {
		allowUsage: presets[params.preset]
	};
}

export default presetSample;
