'use strict';

/**
 * @module Cookie Notice
 * @version 1.4
 * @author Wezom agency
 */

import addCookie from './modules/add-cookie';
import receiveCookie from './modules/receive-cookie';
// import addStyle from './modules/add-style';
import updateOptions from './modules/update-options';

// import styleSample from './modules/style-sample';

/**
 * Plugin for working with cookies
 * @param {Object} options
 * @returns {Object}
 */
function cookieNotice (options) {
	const _self = {};

	// Default options
	const _defaults = {
		encrypt: 'none', // 'simple'
		allowUsage: {
			key: 'allow-cookie-usage',
			event: 'allow-cookie-usage',
			htmlClass: 'allow-cookie-usage',
			options: {
				expires: 3600 * 24 * 365,
				path: '/'
			},
			lang: 'en',
			delay: 1000,
			position: 'bottom', // 'bottom-left' | 'bottom-right' | 'top' | 'top-center' | top-left, top-right, center
			markdown: `<div class="cookie-notice %k js-cookie-notice">%i<span>%t%l</span><button class="js-cookie-notice-button">%b</button></div>`,
			preset: 'none',
			container: {
				dir: 'inherit', // 'rtl', 'ltr'
				fontFamily: 'Arial, sans-serif',
				fontSize: '18px',
				bg: '#f5f5f6',
				boxShadow: '0 1px 8px 1px #bcbcbc',
				width: '100%',
				padding: '20px'
			},
			text: {
				color: '#000000',
				text: `This site uses cookies for a more comfortable user experience. By continuing to browse the site, you agree to the use of cookies.`
			},
			button: {
				bg: '#4f4f4f',
				bgHover: '#000000',
				color: '#ffffff',
				boxShadow: '2px 2px 3px 0 #000000',
				borderRadius: '3px',
				padding: '10px 30px',
				text: `Accept`
			},
			link: {
				href: '',
				text: `Detail...`,
				target: true,
				color: '#007bff',
				colorHover: '#0056b3'
			},
			image: {
				src: '',
				width: '',
				height: ''
			}
		}
	};

	if (!navigator.cookieEnabled) {
		console.warn(
			'The browser does not support or is blocking cookies from being set. Please enable cookies support in your browser to work with the site'
		);
	}

	// Result options
	_self.options = updateOptions(_defaults, options);

	// Add styles
	// addStyle(styleSample.askUsage(_self.options.allowUsage), _self.options.allowUsage.key + '-style');

	// If true then cookies is allow
	_self.allowUsage = !!receiveCookie(_self.options.allowUsage.key, {
		encrypt: _self.options.encrypt
	});

	// Adding cookie
	_self.add = function (key, value, options = {}) {
		if (_self.allowUsage) {
			addCookie(key, value, options, {
				encrypt: _self.options.encrypt
			});
		}
	};

	// Receiving cookie by key
	_self.receive = function (key) {
		return _self.allowUsage
			? receiveCookie(key, {
				encrypt: _self.options.encrypt
			})
			: null;
	};

	// Removing cookie by key
	_self.remove = function (key, path = '/', domain = '') {
		_self.add(key, '', {
			path,
			domain,
			expires: -1
		});
	};

	// Update existing options
	_self.updateOptions = function (newOptions) {
		_self.options = updateOptions(_self.options, newOptions);
	};

	// Set new options
	_self.newOptions = function (newOptions) {
		_self.options = updateOptions(_defaults, newOptions);
	};

	// Ask usage of cookies
	_self.askUsage = function () {
		if (!_self.allowUsage) {
			let params = _self.options.allowUsage;

			// Add ask usage block in Body element
			let wrapper = document.createElement('div');
			wrapper.innerHTML = params.markdown.replace('%k', params.key).replace('%t', params.text.text).replace('%b', params.button.text).replace(
				'%l',
				params.link.href
					? ` <a href="${params.link.href}"${params.link.target ? ` target="_blank"` : ``}>${params.link.text}</a>`
					: ``
			).replace(
				'%i',
				params.image.src
					? ` <img src="${params.image.src}" alt=""${params.image.width ? ` width="${params.image.width}"` : ``}${params.image.height ? ` height="${params.image.height}"` : ``}>`
					: ``
			);

			let el = wrapper.querySelector('.js-cookie-notice');
			let button = el.querySelector('.js-cookie-notice-button');
			document.body.appendChild(el);

			// Add event Click on button
			button.addEventListener('click', function (event) {
				event.preventDefault();

				// Add cookie
				addCookie(params.key, '1', params.options, {
					encrypt: _self.options.encrypt
				});

				// Hide ask usage block
				el.classList.remove('active');

				// Add trigger on event
				document.dispatchEvent(new window.Event(params.event));

				// Add class on Html element
				document.documentElement.classList.add(params.htmlClass);

				return false;
			});

			// Add position class
			el.classList.add(params.position);

			// Show ask usage block
			window.setTimeout(function () {
				el.classList.add('active');
			}, +params.delay);
		}
	};

	return _self;
}

export default cookieNotice;
