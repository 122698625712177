'use strict';

// ----------------------------------------
// Imports
// ----------------------------------------

import ModuleLoader from 'wezom-module-loader';

// ----------------------------------------
// Public
// ----------------------------------------

const moduleLoader = new ModuleLoader({
	debug: IS_DEVELOPMENT, // eslint-disable-line no-undef
	importPromise: moduleName => import('./async-module-loaders/' + moduleName),
	initSelector: '.js-init',
	initFunctionName: 'loaderInit',
	loadingClass: '_async-module-loading',
	loadedClass: '_async-module-loaded',
	list: {
		/* eslint-disable key-spacing */
		'jquery-validation--module-loader': 'form',
		'magnific-popup--module-loader':    '[data-mfp]',
		'toggle--module-loader':            '[data-toggle]',
		'scroll--module-loader':            '[data-scroll]',
		'animate--module-loader':			'[data-animate]',
		'mobile-menu--module-loader':		'[data-mobile-menu]',
		'slick--module-loader':		        '[data-slick]',
		'lazy-load--module-loader':			[
			'[data-zzload-source-img]',
			'[data-zzload-source-background-img]',
			'[data-zzload-source-image]',
			'[data-zzload-source-iframe]'
		],
		'draggable-table--module-loader':   '[data-draggable-table]',
		'wrap-media--module-loader':        '[data-wrap-media]',
		'video--module-loader':   	         '[data-video]',
		'line--module-loader':   	         '[data-line]',
		'send-ajax--module-loader':   	     '[data-send-ajax]',
		'effects--module-loader':   	     '[data-effect]'
	}
});

// ----------------------------------------
// Exports
// ----------------------------------------

export default moduleLoader;
