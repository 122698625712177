'use strict';

import { simpleEncode } from './encryption';

/**
 * Adding cookie
 * @param {string} key
 * @param {*} value
 * @param {Object} [options={}]
 * @param {Date|string|number} [options.expires]
 * @param {string} [options.path]
 * @param {string} [options.domain]
 * @param {boolean} [options.secure]
 * @param {Object} [params={}]
 */
function addCookie (key, value, options = {}, params = {}) {
	let expires = options.expires;

	if (typeof expires === 'string') {
		expires = new Date(expires);
	} else if (typeof expires === 'number' && expires) {
		let d = new Date();
		d.setTime(d.getTime() + expires * 1000);
		expires = d;
	}

	if (expires && expires.toUTCString) {
		options.expires = expires.toUTCString();
	}

	if (params.encrypt === 'simple') {
		key = simpleEncode(key);
		value = simpleEncode(value);
	} else {
		key = window.encodeURIComponent(key);
		value = window.encodeURIComponent(value);
	}

	let updatedCookie = `${key}=${value}`;

	for (let propName in options) {
		let propValue = options[propName];
		if (propValue) {
			updatedCookie += `; ${propName}`;
			if (propValue !== true) {
				updatedCookie += `=${propValue}`;
			}
		}
	}

	document.cookie = updatedCookie;
}

export default addCookie;
