'use strict';

/**
 *
 * @type {Object}
 */
const translate = {
	en: {
		text:
			'This site uses cookies for a more comfortable user experience. By continuing to browse the site, you agree to the use of cookies.',
		button: 'Accept',
		link: 'Detail...'
	},
	ru: {
		text:
			'Этот сайт использует cookie-файлы для более комфортной работы пользователя. Продолжая просматривать сайт, вы соглашаетесь на использование куки.',
		button: 'Принимаю',
		link: 'Детальнее...'
	},
	uk: {
		text:
			'Цей сайт використовує файли cookie для більш комфортної роботи користувача. Продовжуючи переглядати сайт, ви погоджуєтеся на використання файлів cookie.',
		button: 'Приймаю',
		link: 'Детальніше...'
	},
	pl: {
		text:
			'Ta strona wykorzystuje pliki cookie, aby zapewnić wygodę użytkownikom. Kontynuując przeglądanie strony, zgadzasz się na użycie plików cookie.',
		button: 'Zaakceptować',
		link: 'Szczegół...'
	},
	fr: {
		text:
			'Ce site utilise des cookies pour une expérience utilisateur plus confortable. En poursuivant votre navigation sur ce site, vous acceptez l\'utilisation de cookies.',
		button: 'Acceptez',
		link: 'Détail...'
	},
	es: {
		text:
			'Este sitio utiliza cookies para una experiencia de usuario más cómoda. Al continuar navegando por el sitio, usted acepta el uso de cookies.',
		button: 'Aceptar',
		link: 'Detalle...'
	},
	pt: {
		text:
			'Este site usa cookies para uma experiência de usuário mais confortável. Ao continuar a navegar no site, você concorda com o uso de cookies.',
		button: 'Aceitar',
		link: 'Detalhe...'
	},
	de: {
		text:
			'Diese Seite verwendet Cookies für eine angenehmere Benutzererfahrung. Durch die weitere Nutzung der Website stimmen Sie der Verwendung von Cookies zu.',
		button: 'Akzeptieren',
		link: 'Detail...'
	},
	it: {
		text:
			'Questo sito utilizza i cookie per un\'esperienza utente più confortevole. Continuando a navigare nel sito, accetti l\'uso dei cookie.',
		button: 'Accettare',
		link: 'Dettaglio...'
	},
	el: {
		text:
			'Αυτός ο ιστότοπος χρησιμοποιεί cookies για πιο άνετη εμπειρία χρήστη. Συνεχίζοντας την περιήγηση στον ιστότοπο, συμφωνείτε με τη χρήση cookie.',
		button: 'Αποδέχομαι',
		link: 'Λεπτομέρεια...'
	},
	he: {
		text: 'אתר זה משתמש בקובצי cookie עבור חווית משתמש נוחה יותר. אם תמשיך לגלוש באתר, אתה מסכים לשימוש בעוגיות.',
		button: 'קבל',
		link: 'פרט...'
	},
	ar: {
		text:
			'يستخدم هذا الموقع ملفات تعريف الارتباط للحصول على تجربة مستخدم أكثر راحة. من خلال الاستمرار في تصفح الموقع ، فإنك توافق على استخدام ملفات تعريف الارتباط.',
		button: 'قبول',
		link: 'التفاصيل...'
	},
	zh: {
		text: '本網站使用cookie來獲得更舒適的用戶體驗。 繼續瀏覽本網站即表示您同意使用cookies。',
		button: '接受',
		link: '詳情...'
	},
	ja: {
		text:
			'このサイトはより快適なユーザーエクスペリエンスのためにクッキーを使用しています。 サイトを閲覧し続けることで、あなたはクッキーの使用に同意したことになります。',
		button: '受け入れます',
		link: '詳細...'
	}
};

export default translate;
