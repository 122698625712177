'use strict';

// ----------------------------------------
// Imports
// ----------------------------------------

import 'assets#/js/polyfills/mdn';
import 'assets#/js/polyfills/promise-polyfill';
import 'assets#/js/modules/modernizr';
import 'assets#/js/ajax-global-config';
import moduleLoader from 'assets#/js/async-module-loader';
// import cursorInit from 'assets#/js/modules/cursor';
import cookieInit from 'assets#/js/modules/cookie';
import windowInjection from 'assets#/js/modules/window-injection';

// ----------------------------------------
// Ready
// ----------------------------------------

window.jQuery(document).ready(function () {
	// cursorInit();
	windowInjection();
	cookieInit();
	moduleLoader.init();
});

if (IS_PRODUCTION) { // eslint-disable-line no-undef
	import('wezom-log');
}
