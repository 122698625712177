'use strict';

import { simpleDecode, simpleEncode } from './encryption';

/* eslint-disable no-useless-escape */

/**
 * Receiving cookie by key
 * @param {string} key
 * @param {Object} [params={}]
 * @returns {*}
 */
function receiveCookie (key, params = {}) {
	let value = null;

	if (params.encrypt === 'simple') {
		key = simpleEncode(key);
	} else {
		key = window.encodeURIComponent(key);
	}

	let matches = document.cookie.match(
		new RegExp(`(?:^|; )${key.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`)
	);
	if (matches) {
		if (params.encrypt === 'simple') {
			value = simpleDecode(matches[1]);
		} else {
			value = window.decodeURIComponent(matches[1]);
		}
	}
	return value;
}

export default receiveCookie;
