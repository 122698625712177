import Notifier from 'assets#/js/modules/noty/notifier';

window.Notifier = Notifier;
const $window = window.jQuery(window);
const $html = window.jQuery(document.documentElement);
const _statusClasses = {
	loading: '_document-is-loading',
	ready: '_document-is-ready',
	loaded: '_window-is-loaded'
};

export default function () {
	$html.removeClass(_statusClasses.loading).addClass(_statusClasses.ready);

	$window.on('load', () => {
		$html.addClass(_statusClasses.loaded);
	});

	window.onafterprint = function () {
		$html.removeClass('is-print-container');
	};
};
