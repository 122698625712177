'use strict';

import translate from './translate';
import concatObjects from './concat-objects';
import presetSample from './preset-sample';

function setTranslate (options, newOptions, name, value) {
	if (!newOptions.allowUsage || !newOptions.allowUsage[name] || !newOptions.allowUsage[name][value]) {
		if (translate[options.allowUsage.lang]) {
			options = concatObjects(options, {
				allowUsage: {
					[name]: {
						[value]: translate[options.allowUsage.lang][name]
					}
				}
			});
		}
	} else if (
		newOptions.allowUsage &&
		newOptions.allowUsage[name] &&
		newOptions.allowUsage[name].text &&
		typeof newOptions.allowUsage[name][value] === 'object'
	) {
		if (newOptions.allowUsage[name][value][options.allowUsage.lang]) {
			options = concatObjects(options, {
				allowUsage: {
					[name]: {
						[value]: newOptions.allowUsage[name][value][options.allowUsage.lang]
					}
				}
			});
		}
	}

	return options;
}

/**
 * Update options
 * @param oldOptions
 * @param newOptions
 */
function updateOptions (oldOptions, newOptions = {}) {
	if (newOptions.allowUsage && newOptions.allowUsage.preset !== 'none') {
		let presetOptions = presetSample(newOptions.allowUsage);
		newOptions = concatObjects(presetOptions, newOptions);
	}

	// newOptions of plugin
	let options = concatObjects(oldOptions, newOptions);

	// Set lang
	if (!newOptions.allowUsage || !newOptions.allowUsage.lang) {
		let lang = document.documentElement.getAttribute('lang');
		if (lang) {
			if (lang.indexOf('-') !== -1) {
				lang = lang.split('-')[0];
			}
			options = concatObjects(options, {
				allowUsage: {
					lang: lang
				}
			});
		}
	}

	// Set RTL option
	if (options.allowUsage.lang === 'he' || options.allowUsage.lang === 'ar') {
		options = concatObjects(options, {
			allowUsage: {
				container: {
					dir: 'rtl'
				}
			}
		});
	}

	// Set message text
	options = setTranslate(options, newOptions, 'text', 'text');

	// Set button text
	options = setTranslate(options, newOptions, 'button', 'text');

	// Set link text
	options = setTranslate(options, newOptions, 'link', 'text');

	return options;
}

export default updateOptions;
